import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';

import { recordEvent } from '@/configs/metricsConfig/metricsConfig';
import { sliceNames } from '@/library/constants/actionTypes';
import { metricNames } from '@/library/constants/metricConstants';
import { uiStrings } from '@/library/constants/uiStrings';
import {
  fetchOttoSites,
  fetchUserAuthorisedSites,
  validateAPMSessionIdAndFetchUserDetails,
} from '@/library/storeSlices/masterData/masterDataActions';
import { getSectionizedSiteListAndDefaultSelectedSite } from '@/library/utils/authUtils';
import { siteState } from '@/library/utils/enums';
import {
  formatAppConfigDataFromCSVFile,
  iConfigData,
  iWOTablePreferences,
} from '@/pages/Administration/administrationUtils';
import { initialTableContentPreference } from '@/pages/ScheduleWorkOrders/scheduleWorkOrdersFrames/ScheduleWorkOrderTable/tableColumnConfig';

interface iMasterData {
  userDetails?: {
    department: string;
    email: string;
    homeOrganization: string;
    name: string;
    password: string;
    role: string;
    supplierName?: string;
    supplierId?: string;
    userGroup: string;
    userGroups?: string[];
    userId: string;
    userOrganizations?: string[];
    sessionId: string;
    tenantId: string;
  };
  userDetailsError?: {
    message?: string;
    status?: number;
  };
  ottoSites?: {
    enabledSites: Array<{
      siteId: string;
      utcOffset: number;
    }>;
  };
  sitesError?: {
    message?: string;
    error: boolean;
  };
  activeSite?: {
    siteId: string;
    state: siteState.ENABLED;
    utcOffset?: number;
  };
  userAuthorisedSites?: string[];
  sectionizedListOfSites?: {
    ottoEnabledUserAuthorizedSites: any[];
    ottoDisabledUserAuthorizedSites: any[];
  };
  userAuthorisedSitesCount?: number;
  isFetchingOttoSites: boolean;
  isFetchingUserAuthorisedSites: boolean;
  ottoAppConfig: iConfigData;
  isFetchingOttoAppConfig: boolean;
  woTablePreferences: iWOTablePreferences;
}

const initialState: iMasterData = {
  isFetchingOttoSites: false,
  isFetchingUserAuthorisedSites: false,
  ottoAppConfig: {
    adminTabsAuthorisedGroups: [],
    overviewTabAuthorisedGroups: [],
    scheduleWOTabAuthorisedGroups: [],
    ottoEnabledSites: [],
    noOfCardsOnOverviewPage: 21,
    timezoneOffsetWithRespectToUTC: 0,
    schedulingBlockReplacements: {},
    unassignedReasonCodeToDescriptionMapping: {},
    schedulingBlocksList: [],
    workOrderOttoStatusTypes: {},
    numberOfDatesForSchedulingWorkOrder: 4,
    lockResetApiTimer: 0,
    lockSessionIdleDisclaimerTimeout: 0,
    lockSessionEndTimeout: 0,
    idleSessionPromptTimeout: 0,
    ottoWikiLink: '',
    schedulingBlockOverwriteReasons: [],
    overwriteReasonsList: [],
    scheduleWorkOrdersTabEnabledSites: [],
    navBarNeedHelpLink: '',
  },
  isFetchingOttoAppConfig: true,
  woTablePreferences: {
    pageSize: 10,
    contentDisplay: initialTableContentPreference,
  },
};

const masterDataSlice = createSlice({
  name: sliceNames.masterData,
  initialState,
  reducers: {
    updatedSelectedSite: (state, action) => {
      state.activeSite = action.payload;
    },
    updateOttoAppConfig: (state, action) => {
      state.ottoAppConfig = formatAppConfigDataFromCSVFile(action.payload);
    },
    updateWOTablePreferences: (state, action) => {
      state.woTablePreferences = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(PURGE, () => initialState);
    builder.addCase(
      validateAPMSessionIdAndFetchUserDetails.fulfilled,
      (state, action) => {
        state.userDetails = {
          ...action.payload.userDetails,
          tenantId: action.payload.tenantId,
        };
        state.userDetailsError = {};
      },
    );
    builder.addCase(
      validateAPMSessionIdAndFetchUserDetails.rejected,
      (state, action: Record<string, any>) => {
        state.userDetailsError = {
          status: action.payload?.status ?? '',
          message:
            action.payload?.data?.message || uiStrings.somethingWentWrong,
        };
      },
    );
    builder.addCase(fetchOttoSites.fulfilled, (state, action) => {
      const enabledSites = action.payload.sites.filter(
        siteDetails =>
          siteDetails.siteId !== '*' &&
          state.ottoAppConfig.ottoEnabledSites.includes(siteDetails.siteId),
      );
      state.ottoSites = {
        enabledSites,
      };
      state.isFetchingOttoSites = false;

      if (!state.isFetchingUserAuthorisedSites) {
        const { sectionizedListOfSites, activeSite } =
          getSectionizedSiteListAndDefaultSelectedSite(
            enabledSites,
            state.userAuthorisedSites,
            state.activeSite,
            state.ottoAppConfig.timezoneOffsetWithRespectToUTC,
          );
        state.sectionizedListOfSites = sectionizedListOfSites;
        state.activeSite = activeSite;
        recordEvent(
          metricNames.overview_defaultSiteOnAppLoad,
          { activeSite, userDetails: state.userDetails },
          true,
        );
      }
    });
    builder.addCase(fetchOttoSites.pending, state => {
      state.isFetchingOttoSites = true;
    });
    builder.addCase(fetchOttoSites.rejected, state => {
      state.isFetchingOttoSites = false;
    });
    builder.addCase(fetchUserAuthorisedSites.pending, state => {
      state.isFetchingUserAuthorisedSites = true;
    });
    builder.addCase(fetchUserAuthorisedSites.rejected, state => {
      state.isFetchingUserAuthorisedSites = false;
    });
    builder.addCase(fetchUserAuthorisedSites.fulfilled, (state, action) => {
      const { totalSitesCount, listOfSites } = action.payload;
      const siteIDs = listOfSites
        .filter(siteDetails => siteDetails.uog_org !== '*')
        .map(siteDetails => siteDetails.uog_org);
      state.userAuthorisedSites = siteIDs;
      state.userAuthorisedSitesCount = listOfSites.find(
        siteDetails => siteDetails.uog_org === '*',
      )
        ? parseInt(totalSitesCount) - 1
        : parseInt(totalSitesCount);

      state.isFetchingUserAuthorisedSites = false;

      if (!state.isFetchingOttoSites) {
        const { sectionizedListOfSites, activeSite } =
          getSectionizedSiteListAndDefaultSelectedSite(
            state.ottoSites?.enabledSites,
            siteIDs,
            state.activeSite,
            state.ottoAppConfig.timezoneOffsetWithRespectToUTC,
          );
        state.sectionizedListOfSites = sectionizedListOfSites;
        state.activeSite = activeSite;
        recordEvent(
          metricNames.overview_defaultSiteOnAppLoad,
          { activeSite, userDetails: state.userDetails },
          true,
        );
      }
    });
  },
});

export default masterDataSlice.reducer;
