import { appConstants } from '@/library/constants/appConstants';
import { uiStrings } from '@/library/constants/uiStrings';

const months = [
  uiStrings.january,
  uiStrings.february,
  uiStrings.march,
  uiStrings.april,
  uiStrings.may,
  uiStrings.june,
  uiStrings.july,
  uiStrings.august,
  uiStrings.september,
  uiStrings.october,
  uiStrings.november,
  uiStrings.december,
];

const monthsShortForm = [
  uiStrings.januaryAbbreviation,
  uiStrings.februaryAbbreviation,
  uiStrings.marchAbbreviation,
  uiStrings.aprilAbbreviation,
  uiStrings.mayAbbreviation,
  uiStrings.juneAbbreviation,
  uiStrings.julyAbbreviation,
  uiStrings.augustAbbreviation,
  uiStrings.septemberAbbreviation,
  uiStrings.octoberAbbreviation,
  uiStrings.novemberAbbreviation,
  uiStrings.decemberAbbreviation,
];

const weekdays = [
  uiStrings.monday,
  uiStrings.tuesday,
  uiStrings.wednesday,
  uiStrings.thursday,
  uiStrings.friday,
  uiStrings.saturday,
  uiStrings.sunday,
];
const weekdaysShortForm = [
  uiStrings.mondayAbbreviation,
  uiStrings.tuesdayAbbreviation,
  uiStrings.wednesdayAbbreviation,
  uiStrings.thursdayAbbreviation,
  uiStrings.fridayAbbreviation,
  uiStrings.saturdayAbbreviation,
  uiStrings.sundayAbbreviation,
];

interface iDateComponents {
  MMMM: string; // Full month string - ex: January
  MMM: string; // Short month string - ex: Jan
  MM: string; // 2 digits Month Number - ex: 01, 12
  M: string; // 1 or 2 digits Month Number - ex: 1, 12
  dddd: string; // Full Week day string - ex: Monday
  ddd: string; // Short Week day string - ex: Mon
  DD: string; // 2 digits Date - ex: 01, 20
  D: string; // 1 or 2 digits Date - ex: 1, 20
  YYYY: string; // Full year string - ex: 2023
  YY: string; // Short / 2 digits year string - ex: 23
  hh: string; // hours from 0 to 23
  mm: string; // minutes from 0 to 59
}

export function getDateComponents(date: Date): iDateComponents {
  const mm = date.getMonth() + 1;
  const dd = date.getDate();
  const yyyy = date.getFullYear();
  const ddd = date.getDay();
  const hh = date.getHours();
  const min = date.getMinutes();

  return {
    dddd: weekdays[ddd],
    ddd: weekdaysShortForm[ddd],
    DD: dd < 10 ? `0${dd}` : `${dd}`,
    D: `${dd}`,
    MMMM: months[mm - 1],
    MMM: monthsShortForm[mm - 1],
    MM: mm < 10 ? `0${mm}` : `${mm}`,
    M: `${mm}`,
    YYYY: `${yyyy}`,
    YY: `${yyyy}`.slice(2),
    hh: hh < 10 ? `0${hh}` : `${hh}`,
    mm: min < 10 ? `0${min}` : `${min}`,
  };
}

/* *
 * @param {Date} date
 * @param {string} outputFormat - format in which date is required. Wrap individual date element in curly braces. ex: {YYYY}, {MMM} {DD}
 * @returns {string} - paramNames will be replaced with paramValues in endpoint,
 * */

export function formatDate(date: Date, outputFormat: string): string {
  const dateComponents = getDateComponents(date);
  let formattedDate = outputFormat;

  // Note - Sequence in which strings are being replaced is important - ex: MM should not be replaced before MMM
  formattedDate = formattedDate.replaceAll('{MMMM}', dateComponents.MMMM);
  formattedDate = formattedDate.replaceAll('{MMM}', dateComponents.MMM);
  formattedDate = formattedDate.replaceAll('{MM}', dateComponents.MM);
  formattedDate = formattedDate.replaceAll('{M}', dateComponents.M);
  formattedDate = formattedDate.replaceAll('{dddd}', dateComponents.dddd);
  formattedDate = formattedDate.replaceAll('{ddd}', dateComponents.ddd);
  formattedDate = formattedDate.replaceAll('{DD}', dateComponents.DD);
  formattedDate = formattedDate.replaceAll('{D}', dateComponents.D);
  formattedDate = formattedDate.replaceAll('{YYYY}', dateComponents.YYYY);
  formattedDate = formattedDate.replaceAll('{YY}', dateComponents.YY);
  formattedDate = formattedDate.replaceAll('{hh}', dateComponents.hh);
  formattedDate = formattedDate.replaceAll('{mm}', dateComponents.mm);
  return formattedDate;
}

export const addNoOfDaysToDate = (
  date = getUTCDate(),
  numberOfDaysToAdd: number,
): Date => {
  const currentDate = new Date(date.getTime());
  return new Date(
    currentDate.setDate(currentDate.getDate() + numberOfDaysToAdd),
  );
};

export const getDateUsingTimeZoneOffSet = (
  date: Date,
  utcOffSetInHours: number,
): Date => new Date(date.getTime() + utcOffSetInHours * 60 * 60 * 1000);

export const getUTCDate = (date = new Date()): Date =>
  getDateUsingTimeZoneOffSet(date, new Date().getTimezoneOffset() / 60);

export const getUTCDateByDateString = (dateString: string): Date =>
  getDateUsingTimeZoneOffSet(
    new Date(dateString),
    new Date(dateString).getTimezoneOffset() / 60,
  );

export const getDaysDifferenceBetweenDates = (
  startDate: Date,
  endDate: Date,
): number => {
  const startDateFormatted = getUTCDateByDateString(
    formatDate(startDate, appConstants.dateFormat),
  );
  const endDateFormatted = getUTCDateByDateString(
    formatDate(endDate, appConstants.dateFormat),
  );
  return (
    (endDateFormatted.getTime() - startDateFormatted.getTime()) /
    (1000 * 60 * 60 * 24)
  );
};

export const getDatesInRange = (startDate, endDate): Date[] => {
  const dateArray: Date[] = [];
  let currentDate = new Date(startDate.getTime());
  while (currentDate <= endDate) {
    dateArray.push(new Date(currentDate));
    currentDate = addNoOfDaysToDate(currentDate, 1);
  }
  return dateArray;
};

export const convertMillisecondsToMinutes = (milliseconds): number =>
  Math.floor(milliseconds / (1000 * 60));
