import { createSlice } from '@reduxjs/toolkit';

import { iMessageDefinition } from '@/library/components/atoms/Banner';
import { sliceNames } from '@/library/constants/actionTypes';
import { uiStrings } from '@/library/constants/uiStrings';

import {
  editWORecommendation,
  fetchAllShiftsForSite,
  fetchAllTechniciansForSite,
  fetchRecommendationGenerationStatusForSite,
  fetchTechniciansConfigurationForWO,
  fetchWORecommendations,
  fetchWORecommendationsMetaData,
  fetchWorkOrderTypeToScreenIdMappingRequest,
  resetFilteredWORecommendationsTableList,
  startWOEditSession,
  stopWOEditSession,
  updateAppliedTableFilters,
  updateSelectedWOs,
} from './scheduleWODataActions';
import {
  iFilterKeys,
  iFormattedRecommendationsData,
  iRecommendationGenerationStatus,
  iRecommendationsMetadata,
  iShiftData,
  iShiftLaborRollupData,
  iSortedColumn,
  iTechBandwidth,
  iTechnicianConfiguration,
  iWOTableErrorsData,
  iWOTypeToAPMScreenIDMapping,
} from './scheduleWODataTypes';
import {
  defaultWOSortingComparator,
  formatRecommendationGenerationData,
  formatRecommendationsData,
  getFilteredListOfWOs,
  getFormattedTechBandwidthData,
  getShiftLaborRollupData,
  getWorkOrdersTableErrorsData,
} from './scheduleWODataUtils';

export const filterKeys = {
  schStartDate: 'schStartDate',
  dueDate: 'dueDate',
  shift: 'shift',
  woOwner: 'woOwner',
  schBlock: 'schBlock',
  ottoStatus: 'ottoStatus',
  unassignedReasons: 'unassignedReasons',
  hideErrors: 'hideErrors',
};

export const initialAppliedFilters = {
  [filterKeys.schStartDate]: [],
  [filterKeys.dueDate]: [],
  [filterKeys.shift]: [],
  [filterKeys.woOwner]: [],
  [filterKeys.schBlock]: [],
  [filterKeys.ottoStatus]: [],
  [filterKeys.unassignedReasons]: [],
  [filterKeys.hideErrors]: [],
};

interface iScheduleWOData {
  woRecommendationsMasterDataWithUserEdits: iFormattedRecommendationsData[];
  woRecommendationsMasterDataWithoutUserEdits: iFormattedRecommendationsData[];
  woRecommendationsFilteredTableDataWithUserEdits: iFormattedRecommendationsData[];
  woRecommendationsFilteredTableDataWithoutUserEdits: iFormattedRecommendationsData[];
  selectedWOs: iFormattedRecommendationsData[];
  shiftLaborRollupData: iShiftLaborRollupData;
  isAnyEditMade: boolean;
  isEditSessionActive: boolean;
  acquiringEditSessionLockInProgress: boolean;
  releasingEditSessionLockInProgress: boolean;
  appliedFilters: iFilterKeys;
  selectedSite: string;
  isFetchingAllShifts: boolean;
  shiftList: iShiftData[];
  shiftListToDateMap: Record<string, iShiftData[]>;
  fetchAllShiftsError: string;
  isFetchingAllTechnicians: boolean;
  fetchAllTechniciansError: string;
  techniciansList: String[];
  isFetchingTechniciansForDate: Record<string, boolean>;
  fetchTechniciansForDateError: Record<string, string>;
  techniciansConfigListForDate: Record<string, iTechnicianConfiguration[]>;
  workOrdersTableErrors: iWOTableErrorsData;
  workOrdersWithoutUserEditsTableErrors: iWOTableErrorsData;
  scheduleWOMessageBannerData: iMessageDefinition[];
  techBandwidth: iTechBandwidth;
  recommendationsMetadata: iRecommendationsMetadata;
  isFetchingWORecommendations: boolean;
  recommendationGenerationStatus: iRecommendationGenerationStatus;
  sortedColumn: iSortedColumn;
  startSessionTimestamp: number;
  woTypeToAPMScreenIdMapping: iWOTypeToAPMScreenIDMapping[];
}

const initialState: iScheduleWOData = {
  woRecommendationsMasterDataWithUserEdits: [],
  woRecommendationsMasterDataWithoutUserEdits: [],
  woRecommendationsFilteredTableDataWithUserEdits: [],
  woRecommendationsFilteredTableDataWithoutUserEdits: [],
  selectedWOs: [],
  shiftLaborRollupData: {},
  isAnyEditMade: false,
  isEditSessionActive: false,
  acquiringEditSessionLockInProgress: false,
  releasingEditSessionLockInProgress: false,
  appliedFilters: initialAppliedFilters,
  selectedSite: 'STL5',
  isFetchingAllShifts: false,
  isFetchingAllTechnicians: false,
  fetchAllShiftsError: '',
  fetchAllTechniciansError: '',
  shiftList: [],
  shiftListToDateMap: {},
  techniciansList: [],
  workOrdersTableErrors: {},
  workOrdersWithoutUserEditsTableErrors: {},
  isFetchingTechniciansForDate: {},
  fetchTechniciansForDateError: {},
  techniciansConfigListForDate: {},
  scheduleWOMessageBannerData: [],
  techBandwidth: {},
  recommendationsMetadata: {},
  isFetchingWORecommendations: false,
  recommendationGenerationStatus: {
    isNewRunAvailable: false,
    isNewRunInProgress: false,
    isNewRunFailed: false,
  },
  sortedColumn: {
    columnId: '',
    isAscending: false,
  },
  startSessionTimestamp: 0,
  woTypeToAPMScreenIdMapping: [],
};

const scheduleWODataSlice = createSlice({
  name: sliceNames.scheduleWOData,
  initialState,
  reducers: {
    resetScheduleWOTab: () => initialState,
    disableWORecommendationsLoader: state => {
      state.isFetchingWORecommendations = false;
    },
    updateScheduleWOMessageBanner: (state, action) => {
      if (typeof action.payload === 'string') {
        const index = state.scheduleWOMessageBannerData.findIndex(
          data => data.id === action.payload,
        );
        if (index >= 0) {
          const updatedData = [...state.scheduleWOMessageBannerData];
          updatedData.splice(index, 1);
          state.scheduleWOMessageBannerData = updatedData;
        }
      } else {
        state.scheduleWOMessageBannerData = [
          ...state.scheduleWOMessageBannerData.filter(
            data => data.id !== action.payload.id,
          ),
          action.payload,
        ];
      }
    },
    sortWORecommendations: (state, action) => {
      const { columnId, sortingComparator } = action.payload;
      let sortingComparatorBasedOnAscendingFlag;
      if (
        state.sortedColumn.columnId === columnId &&
        !state.sortedColumn.isAscending
      ) {
        sortingComparatorBasedOnAscendingFlag = defaultWOSortingComparator;

        state.sortedColumn = {
          columnId: '',
          isAscending: false,
        };
      } else {
        sortingComparatorBasedOnAscendingFlag = (a, b): number => {
          const flag = sortingComparator(a, b);
          return state.sortedColumn.columnId !== columnId
            ? flag
            : !state.sortedColumn.isAscending
            ? flag
            : -flag;
        };

        state.sortedColumn = {
          columnId,
          isAscending:
            state.sortedColumn.columnId === columnId
              ? !state.sortedColumn.isAscending
              : true,
        };
      }

      state.woRecommendationsMasterDataWithUserEdits =
        state.woRecommendationsMasterDataWithUserEdits.sort(
          sortingComparatorBasedOnAscendingFlag,
        );
      state.woRecommendationsMasterDataWithoutUserEdits =
        state.woRecommendationsMasterDataWithoutUserEdits.sort(
          sortingComparatorBasedOnAscendingFlag,
        );
      state.woRecommendationsFilteredTableDataWithUserEdits =
        state.woRecommendationsFilteredTableDataWithUserEdits.sort(
          sortingComparatorBasedOnAscendingFlag,
        );
      state.woRecommendationsFilteredTableDataWithoutUserEdits =
        state.woRecommendationsFilteredTableDataWithoutUserEdits.sort(
          sortingComparatorBasedOnAscendingFlag,
        );
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchWORecommendations.rejected, state => {
        state.isFetchingWORecommendations = false;
      })
      .addCase(fetchWORecommendations.pending, state => {
        state.isFetchingWORecommendations = true;
      })
      .addCase(fetchWORecommendations.fulfilled, (state, action) => {
        const { availableLaborBandwidths, processedRecommendationWOs } =
          action.payload;
        const formattedRecommendationsData = formatRecommendationsData(
          processedRecommendationWOs,
          action.payload.ottoAppConfig,
        );
        state.woRecommendationsMasterDataWithUserEdits =
          formattedRecommendationsData;
        state.woRecommendationsMasterDataWithoutUserEdits =
          formattedRecommendationsData;
        state.woRecommendationsFilteredTableDataWithUserEdits =
          formattedRecommendationsData;
        state.woRecommendationsFilteredTableDataWithoutUserEdits =
          formattedRecommendationsData;

        const techBandwidthData = getFormattedTechBandwidthData(
          availableLaborBandwidths,
        );

        state.techBandwidth = techBandwidthData;
        const shiftLaborRollupData = getShiftLaborRollupData(
          formattedRecommendationsData,
          techBandwidthData,
        );

        state.shiftLaborRollupData = shiftLaborRollupData;
        state.isAnyEditMade = false;
        const tableErrors = getWorkOrdersTableErrorsData(
          formattedRecommendationsData,
          shiftLaborRollupData,
        );
        state.workOrdersTableErrors = tableErrors;
        state.workOrdersWithoutUserEditsTableErrors = tableErrors;
        state.isFetchingWORecommendations = false;
      })
      .addCase(
        fetchWorkOrderTypeToScreenIdMappingRequest.fulfilled,
        (state, action) => {
          state.woTypeToAPMScreenIdMapping = (
            action.payload?.data?.responseData?.pageData?.grid?.GRIDRESULT?.GRID
              ?.DATA ?? []
          ).map(data => ({
            id: data.id,
            woType: data.wotype,
            woScreenID: data.woscreenid,
            dataspyID: data.dataspyid,
          }));
        },
      )
      .addCase(fetchWORecommendationsMetaData.fulfilled, (state, action) => {
        state.recommendationsMetadata = action.payload;
      })
      .addCase(fetchWORecommendationsMetaData.pending, state => {
        state.isFetchingWORecommendations = true;
      })
      .addCase(fetchWORecommendationsMetaData.rejected, state => {
        state.isFetchingWORecommendations = false;
      })
      .addCase(updateSelectedWOs, (state, action) => {
        state.selectedWOs = action.payload;
      })
      .addCase(editWORecommendation, (state, action) => {
        const woDetails = {
          ...action.payload,
          isEdited: true,
        };
        state.woRecommendationsFilteredTableDataWithUserEdits[
          state.woRecommendationsFilteredTableDataWithUserEdits.findIndex(
            wo => wo.workOrderID === action.payload.workOrderID,
          )
        ] = woDetails;
        const selectedWOIndex = state.selectedWOs.findIndex(
          wo => wo.workOrderID === action.payload.workOrderID,
        );
        if (selectedWOIndex >= 0) {
          state.selectedWOs[selectedWOIndex] = woDetails;
        }

        state.woRecommendationsMasterDataWithUserEdits[
          state.woRecommendationsMasterDataWithUserEdits.findIndex(
            wo => wo.workOrderID === action.payload.workOrderID,
          )
        ] = woDetails;

        const shiftLaborRollupData = getShiftLaborRollupData(
          state.woRecommendationsMasterDataWithUserEdits,
          state.techBandwidth,
        );
        state.shiftLaborRollupData = shiftLaborRollupData;
        state.isAnyEditMade = true;
        state.workOrdersTableErrors = getWorkOrdersTableErrorsData(
          state.woRecommendationsMasterDataWithUserEdits,
          shiftLaborRollupData,
        );
      })
      .addCase(startWOEditSession.fulfilled, (state, action) => {
        if (
          action.payload.status === 'Success' ||
          action.payload.latestLockDetail.ownerEmail ===
            action.payload.currentUserId
        ) {
          state.isEditSessionActive = true;
          state.startSessionTimestamp = Date.now();
        }

        state.acquiringEditSessionLockInProgress = false;
      })
      .addCase(startWOEditSession.pending, state => {
        state.acquiringEditSessionLockInProgress = true;
      })
      .addCase(stopWOEditSession.fulfilled, state => {
        state.isEditSessionActive = false;
        state.startSessionTimestamp = 0;
        state.releasingEditSessionLockInProgress = false;
      })
      .addCase(stopWOEditSession.pending, state => {
        state.releasingEditSessionLockInProgress = true;
      })
      .addCase(updateAppliedTableFilters, (state, action) => {
        state.appliedFilters = action.payload;
      })
      .addCase(resetFilteredWORecommendationsTableList, (state, action) => {
        const undoUserEdits = action.payload;
        const recommendationsData = undoUserEdits
          ? state.woRecommendationsMasterDataWithoutUserEdits
          : state.woRecommendationsMasterDataWithUserEdits;
        const filteredList: iFormattedRecommendationsData[] =
          getFilteredListOfWOs(
            state.appliedFilters,
            state.workOrdersTableErrors,
            recommendationsData,
          );
        state.woRecommendationsMasterDataWithUserEdits = recommendationsData;
        state.woRecommendationsFilteredTableDataWithUserEdits = filteredList;
        state.woRecommendationsFilteredTableDataWithoutUserEdits = filteredList;
        if (undoUserEdits) {
          state.selectedWOs = [];
        }

        const shiftLaborRollupData = getShiftLaborRollupData(
          recommendationsData,
          state.techBandwidth,
        );
        state.shiftLaborRollupData = shiftLaborRollupData;
        state.isAnyEditMade = undoUserEdits ? false : state.isAnyEditMade;
        state.workOrdersTableErrors = getWorkOrdersTableErrorsData(
          recommendationsData,
          shiftLaborRollupData,
        );
      })
      .addCase(fetchAllTechniciansForSite.pending, state => {
        state.isFetchingAllTechnicians = true;
        state.fetchAllTechniciansError = '';
      })
      .addCase(fetchAllTechniciansForSite.fulfilled, (state, action) => {
        state.isFetchingAllTechnicians = false;
        state.fetchAllTechniciansError = '';
        state.techniciansList = action.payload.technicians;
      })
      .addCase(fetchAllTechniciansForSite.rejected, state => {
        state.isFetchingAllTechnicians = false;
        state.fetchAllTechniciansError = uiStrings.noDataFound;
      })
      .addCase(fetchAllShiftsForSite.pending, state => {
        state.isFetchingAllShifts = true;
        state.fetchAllShiftsError = '';
      })
      .addCase(fetchAllShiftsForSite.fulfilled, (state, action) => {
        const scheduleStartDateToShifts: iScheduleWOData['shiftListToDateMap'] =
          {};
        const shiftList: iShiftData[] = [];
        const { shiftData } = action.payload;
        shiftData.forEach(shiftsForDate => {
          scheduleStartDateToShifts[shiftsForDate.scheduleStartDate] =
            shiftsForDate.shiftDetails;
          shiftList.push(...shiftsForDate.shiftDetails);
        });
        const uniqueShiftList: iShiftData[] = [];
        shiftList.forEach(shift => {
          if (
            !uniqueShiftList.find(
              uniqueShift => uniqueShift.shiftId === shift.shiftId,
            )
          ) {
            uniqueShiftList.push(shift);
          }
        });
        state.isFetchingAllShifts = false;
        state.shiftListToDateMap = scheduleStartDateToShifts;
        state.shiftList = uniqueShiftList;
        state.fetchAllShiftsError = '';
      })
      .addCase(fetchAllShiftsForSite.rejected, state => {
        state.isFetchingAllShifts = false;
        state.fetchAllShiftsError = uiStrings.noDataFound;
      })
      .addCase(fetchTechniciansConfigurationForWO.pending, (state, action) => {
        const { techAvailabilityDate } = action.meta.arg;
        state.isFetchingTechniciansForDate = {
          ...state.isFetchingTechniciansForDate,
          [techAvailabilityDate]: true,
        };
        state.fetchTechniciansForDateError = {
          ...state.fetchTechniciansForDateError,
          [techAvailabilityDate]: '',
        };
      })
      .addCase(
        fetchTechniciansConfigurationForWO.fulfilled,
        (state, action) => {
          const { techniciansConfigurationList, techAvailabilityDate } =
            action.payload;
          state.isFetchingTechniciansForDate = {
            ...state.isFetchingTechniciansForDate,
            [techAvailabilityDate]: false,
          };
          state.fetchTechniciansForDateError = {
            ...state.fetchTechniciansForDateError,
            [techAvailabilityDate]: '',
          };
          state.techniciansConfigListForDate = {
            ...state.techniciansConfigListForDate,
            [techAvailabilityDate]: techniciansConfigurationList,
          };
        },
      )
      .addCase(
        fetchTechniciansConfigurationForWO.rejected,
        (state, action: any) => {
          const { techAvailabilityDate } = action.meta.arg;
          state.isFetchingTechniciansForDate = {
            ...state.isFetchingTechniciansForDate,
            [techAvailabilityDate]: false,
          };
          state.fetchTechniciansForDateError = {
            ...state.fetchTechniciansForDateError,
            [techAvailabilityDate]: uiStrings.noDataFound,
          };
        },
      )
      .addCase(
        fetchRecommendationGenerationStatusForSite.fulfilled,
        (state, action: any) => {
          state.recommendationGenerationStatus =
            formatRecommendationGenerationData(
              action.payload,
              action.payload.modelRunDate ??
                state.recommendationsMetadata.modelRunDate,
            );
        },
      );
  },
});

export default scheduleWODataSlice.reducer;
