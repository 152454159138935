export const uiStrings = {
  backlogDaysDescription:
    'Days of Backlog (normalized) – Actuals: Number of days needed in future to cover the existing backlog of work orders. To calculate the metric, estimated hours required to perform backlog work orders is divided by the available downtime hours in the next week and the result is multiplied by 7 (Number of days in a week).',
  comparisonChartEmptyGraphDescription:
    'Labor Planning Summary - Future Forecast was unable to load.',
  comparisonChartEmptyGraphHeader: 'No data available',
  comparisonChartErrorText: 'Error loading data',
  comparisonChartLoadingText: 'Loading data',
  comparisonChartXAxis: 'Date',
  comparisonChartYAxis: 'Utilization %',
  comparisonGraphHeader: 'Labor Planning Summary - Future Forecast',
  comparisonGraphPopoverDTWTitle: 'Utilization DTW',
  comparisonGraphPopoverORTTitle: 'Utilization ORT',
  comparisonGraphPopoverOverallTitle: 'Utilization Overvall',
  comparisonGraphSubHeader:
    'Shows the Forecasted EAM Shift Utilization % Overall, ORT and DTW for the {noOfCardsOnOverviewPage} day planning horizon as of Monday each week',
  daysOfBacklog: 'Days of Backlog',
  dtwUtilization: 'DTW Utilization',
  labourUtilizationOverall: 'Labor Utilization Overall',
  labourUtilization: 'Labor Utilization',
  ortUtilization: 'ORT Utilization',
  retry: 'Retry',
  overviewTabLabel: 'Overview',
  administrationTabLabel: 'Administration',
  scheduleWorkOrder: 'Schedule Work Orders',
  forecastTabLabel: 'Forecast',
  settings: 'Settings',
  labourPlanningButtonLabel: 'View Labor Planning QuickSite',
  initiateSSO: 'Initiate Single Sign-On with Amazon',
  verifyingOttoUser: 'Verifying OTTO User',
  passwordPlaceholder: 'Please enter EAM Password *',
  signInButtonLabel: 'Sign In',
  selectAllPages: 'Select All Pages',
  unSelectAllPages: 'Unselect All Pages',
  overwrite: 'Overwrite',
  close: 'Close',
  workOrderAbbreviation: 'WO',
  shift: 'Shift',
  dueDate: 'Due Date',
  shifts: 'Shifts',
  schStDate: 'Sch. St. Date',
  scheduleDate: 'Schedule Date',
  technician: 'Technician',
  schBlock: 'Sch. Block',
  woExecution: 'WO execution',
  ottoRecommendedData: 'Otto Recommended Date',
  selectDateBetween: 'Select a start date between',
  selectReasonForOverwriting:
    'Select why you are overwriting the recommendation',
  enterReasonForOverwriting: 'Enter reason for overwriting the recommendation.',
  required: 'Required',
  comments: 'Comments',
  yyyymmddDateFormat: 'YYYY/MM/DD',
  noDataFound: 'No data found!',
  undoChanges: 'Undo Changes',
  downloadExcel: 'Download Excel',
  endorse: 'Endorse',
  confirm: 'Confirm',
  refresh: 'Refresh',
  confirmUndo: 'Confirm Undo',
  confirmEndorsement: 'Confirm Endorsement',
  revertToPreviousRun: 'Revert to Previous Daily Run',
  loadNewRun: 'Load New Daily Run',
  undoConfirmationHeading: 'Are you sure you want to undo changes?',
  refreshConfirmationHeading:
    'Are you sure you want to refresh before endorsing?',
  refreshConfirmationDescription:
    'This action will not save any updates you made to the work orders and load latest Otto recommendations if you do not endorse your edits.',
  endorseConfirmationHeading:
    'Are you sure you want to endorse these work orders?',
  endorseConfirmationNewRunInProgressHeading:
    'New run is in progress. Are you sure you want to Endorse?',
  undoConfirmationDescription:
    'This action will revert any updates you have made to work orders (Schedule Start Dates, Shifts, Assigned To, etc) back to Otto Recommendations.',
  changesWillBeLostPermanently: 'Your changes will be lost permanently.',
  endSessionConfirmationHeading: 'Are you sure you want to end your session?',
  endSessionConfirmationDescription:
    'You have made edits to the work order schedule that will not be saved. To save, endorse changes and then end your session.',
  assignedTo: 'Assigned to',
  technicians: 'Technicians',
  ottoStatus: 'Otto Status',
  startSession: 'Start Session',
  endSession: 'End Session',
  sessionInProgress: 'Session in progress',
  sessionInProgressDescription:
    'You are editing this page. Other users are not able to edit while you are in an active session.',
  sessionHasBeenIdle: 'The session has been idle for {minutes} minutes.',
  startSessionInstructions:
    'To Edit or Endorse recommendations, you need to click the "Start Session" button.',
  apply: 'Apply',
  cancel: 'Cancel',
  clearFilters: 'Clear Filters',
  all: 'All',
  selectStartDateFirst: 'Please select Sch. Start Date first.',
  noShiftFoundForDate: 'No Shifts found for selected Sch. Start Date.',
  somethingWentWrong: 'Sorry, something went wrong!',
  startDateIsMandatory: "'Schedule Start Date' is mandatory.",
  selectShiftsForAllTechnicians:
    'Shifts for Assigned To and {techCount} Additional technician[s] must be selected.',
  allTechniciansMustBeSelected:
    'Assigned To and {techCount} Additional technician[s] must be selected.',
  woOwnerMustBeSelected: 'Assigned To must be selected.',
  secondaryOwnersMustBeSelected: 'Additional technicians must be selected.',
  shiftLaborRollupMustNotExceed:
    'Shift Labor Rollup must not exceed 80% for any technician in a shift for a particular start date.',
  error: 'Error',
  selectStartDateAndShiftForTechnician:
    'Please select Schedule Start date and Shift for this technician first.',
  selectShiftForTechnician: 'Please select a Shift for this technician first',
  qualifiedTechniciansSameShift: 'Qualified Technicians (Same Shift)',
  qualifiedTechniciansDifferentShift: 'Qualified Technicians (Different Shift)',
  qualifiedTechnicians: 'Qualified Technicians',
  nonQualifiedTechnicians: 'Non-Qualified Technicians',
  zeroQualifiedTechnicians: 'Technicians with Zero Qualifications',
  resolveErrorsForSelectedWOs:
    'Errors for all selected WOs must be fixed before endorsing',
  resolveErrorsForAllWOs: 'Errors for all WOs must be fixed before endorsing',
  schStartDate: 'Sch. Start Date',
  additionalTechnicians: 'Additional Technicians',
  shiftLaborRollup: 'Shift Labor Rollup',
  woDescription: 'WO Description',
  tacAssets: 'TAC Assets',
  estHrs: 'Est. Hrs.',
  woId: 'WO ID',
  equipmentAlias: 'Equipment Alias',
  techniciansRequired: 'Technicians Required',
  and: 'and',
  january: 'January',
  february: 'February',
  march: 'March',
  april: 'April',
  may: 'May',
  june: 'June',
  july: 'July',
  august: 'August',
  september: 'September',
  october: 'October',
  november: 'November',
  december: 'December',
  januaryAbbreviation: 'Jan',
  februaryAbbreviation: 'Feb',
  marchAbbreviation: 'Mar',
  aprilAbbreviation: 'Apr',
  mayAbbreviation: 'May',
  juneAbbreviation: 'Jun',
  julyAbbreviation: 'Jul',
  augustAbbreviation: 'Aug',
  septemberAbbreviation: 'Sep',
  octoberAbbreviation: 'Oct',
  novemberAbbreviation: 'Nov',
  decemberAbbreviation: 'Dec',
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  saturday: 'Saturday',
  sunday: 'Sunday',
  mondayAbbreviation: 'Mon',
  tuesdayAbbreviation: 'Tue',
  wednesdayAbbreviation: 'Wed',
  thursdayAbbreviation: 'Thu',
  fridayAbbreviation: 'Fri',
  saturdayAbbreviation: 'Sat',
  sundayAbbreviation: 'Sun',
  filteredWOs: 'Filtered WOs',
  totalWOs: 'Total WOs',
  forecastedDataError: "Could not retrieve Site's Forecasted Data",
  dateFormat: 'MM/DD/YYYY',
  status: 'Status',
  couldNotFetchAllSites: 'Could not fetch all sites!',
  loadMoreDates: 'Load More Dates',
  equipmentDescription: 'Equipment Description',
  criticality: 'Criticality',
  tacRequirements: 'TAC Requirement',
  precedenceScore: 'Precedence Score',
  woType: 'WO Type',
  pmIntrusiveType: 'Preventive Intrusive Type',
  unassignedReason: 'Unassigned Reason',
  woStatus: 'WO Status',
  estLabor: 'Est. Labor',
  viewRecommendedChanges: 'View Recommended Changes',
  utilization: 'Utilization',
  recommendedWorkOrdersAdditionForDate:
    'Recommended Work Order Additions for {date}',
  recommendedWorkOrdersToRemoveForDate:
    'Recommended Work Order To Remove for {date}',
  recommended: 'Recommended',
  downloadCSV: 'Download CSV',
  remove: 'Remove',
  hoursAvailable: 'Hours Available',
  hoursOver: 'Hours Over',
  hoursToRemove: 'Hours To Remove',
  workOrdersAssigned: 'work orders assigned',
  ottoEnabledSites: 'Otto Enabled Sites',
  ottoDisabledSites: 'Otto Disabled Sites',
  hoursToRemoveDescriptionHeading:
    "Why does the hours to remove not match what's recommended below?",
  hoursToRemoveDescriptionPara1:
    "The forecast data presented is based on scheduled proactive work (i.e. - PM, FPM, Repairs) plus a prediction of reactive, training, project or administrative work, based on the site's historical booked labor.",
  hoursToRemoveDescriptionPara2:
    "If there are not enough scheduled work orders to remove, the demand could be based on the predicted work - some of which are in the site's control to postpone to another day. Proper labor booking and APM/EAM shift configuration will ensure labor utilization forecast accuracy.",
  hoursAvailableDescriptionHeading:
    'Why do the hours available not match what’s recommended below?',
  hoursAvailableDescriptionPara:
    'The work orders present represent highly ranked backlog items (ranked by factors including health, criticality, priority and age) or oldest work order numbers. If there is not enough demand for additional work that can be scheduled, it is recommended to inspect the facility for new repairs needed, develop technicians, consult with Operations for project work, support nearby sites and finally, offer paid & voluntary time off until supply and demand are balanced.',
  fetchingWorkOrders: 'Fetching Work Orders',
  unableToLoadLaborUtilizationData: 'Unable to load Labor Utilization data',
  userNotAuthorisedError: "You're not authorised to access Otto!",
  fetchingAppConfiguration: 'Fetching app configuration.',
  noneAvailable: 'None Available',
  siteNotProperlyConfigured: 'Site not properly configured.',
  fetchingUtilisationData: "Fetching site's utilization data.",
  fetchingListOfOttoSites: 'Fetching list of sites.',
  welcomeToOtto: 'Welcome to  Otto',
  noSitesSetupCurrently: 'You do not currently have any sites set up.',
  noSitesSetupCurrentlyDisclaimer:
    'Please reach out to the Global Planning Team or your Senior Planner for more information on Otto and if your site is eligible at this time.',
  selectSite: 'Select Site',
  site: 'Site',
  sessionInProgressErrorDescription:
    'Another user ( {username} ) is currently editing the page. No additional edits can be made at this time.',
  inactiveForAWhile: 'You have been inactive for a while!',
  loseAccessAfterCertainDuration:
    'The session will automatically end after it has been idle for {maximumIdleDuration} min.',
  editingSessionExpired: 'Your Editing Session has expired.',
  editingSessionEnded: 'Your Editing Session has ended.',
  editingSessionExpiredDescription:
    'Due to {maximumIdleDuration} minutes of continuous idle time on Schedule Work Orders page, your Edit Session has expired and your changes have been reverted.',
  editingSessionEndedDueToTimerResetFailure:
    'Your editing session has ended. This happens when you end your session in a different tab or browser.',
  reasonCode: 'Reason Code',
  devConfig: 'Dev Config',
  listOfFieldsInConfig: 'List of fields in Config file',
  configFieldLabels: 'Config field Labels',
  reset: 'Reset',
  save: 'Save',
  softFailureDescription:
    'Otto Work Order Schedule Recommendation for {siteId} was not generated or only a partial recommendation was generated.',
  systemFailure: 'System failure.',
  lastAvailableRunData: 'The last available run was on {date}',
  noLastSuccessfulRun: 'There is no successful run available for this site.',
  errorCodesFound: 'Error Codes Found: ',
  forMoreInformationVisit: 'For more information visit the',
  ottoWiki: 'Otto Wiki',
  fetchingWORecommendations: 'Fetching Work Order Recommendations',
  unselect: 'Unselect',
  unassignWorkOrder: 'Unassign Work Order',
  selectMaxThreeReasons: 'Select maximum 3 reasons',
  selectDifferentSiteConfirmationTitle:
    'Are you sure you want to select a different site?',
  siteChangeEndSessionConfirmationDescription:
    'You are in an active session. Changing sites will cause the session to end and all edits to work orders will be lost.',
  closeAndEndorseBeforeSwitchingSite:
    'Please close and endorse work order edits before selecting a different site if you wish to save changes.',
  selectDifferentSite: 'Select Different Site',
  lastRunTime: 'Last Run Time',
  comingSoon: 'Coming Soon!',
  featureNotAvailableForSite:
    'This feature is not currently available for the selected site.',
  woEndorsedSuccessfully:
    'Endorsement submission for {woCount} Work Orders is successful. These changes will take a few minutes to update in APM.',
  woEndorsementPending: 'Work Orders endorsement submission is in progress.',
  couldNotEndorseWorkOrders:
    'Endorsement submission has failed. Please retry to submit your changes again.',
  newRunAvailable: 'Daily Run Available',
  newRecommendationsAvailableForSite:
    'New Otto Recommendations are available for this site.',
  refreshSchedule: 'Refresh Schedule',
  dailyRunInProgress:
    'Otto Daily Run for this site is in progress. Once it is completed, new recommendations will be available.',
  newRunAvailableConfirmUndo:
    'A new daily run has become available. Would you like to revert to previous daily run or load the new daily run?',
  newRunAvailableConfirmEndorsement:
    'A new daily run has become available. Would you like to endorse {count} work orders or load the new daily run?',
  newRunInProgressConfirmEndorsement:
    'A new daily run is in progress. Would you like to endorse {count} work orders or wait for the new recommendations to generate?',
  bothOptionsWillRevertChanges:
    'Both options will not save any changes you have made.',
  endorsementConfirmationMessage:
    'You are about to endorse {count} work orders. These changes will be reflected in APM and assigned to technicians.',
  cannotUndoThis: 'You cannot undo this.',
  acceptableUtilizationRange: 'Acceptable Utilization Range',
  errorHeaderDescription:
    "Unable to endorse the work order because error(s) have been found. All columns must have values and Shift Labor Rollup cannot be higher than technician's available shift hours.",
  shiftLaborRollupHeaderDescription:
    "Shift Labor Rollup = X/Y. X = sum of estimated labor hours of assigned work orders. Y= 80% of technician's schedule",
  ottoStatusEndorsedHeaderDescription:
    'Endorsed = Work Order assigned to technician is showing in APM as endorsed from Otto process.',
  ottoStatusEndorsementPendingHeaderDescription:
    'Endorsement Pending = Work Order has been endorsed from Otto process and is pending update in APM.',
  ottoStatusEndorsementFailedHeaderDescription:
    'Endorsement Failed = Work Order has been endorsed from Otto process and update in APM has failed.',
  ottoStatusChangeRecommendedHeaderDescription:
    'Change Recommended = Work Order is changed to a different date, shift, assigned to and exec window by Otto and is showing in APM as previously endorsed by Otto process.',
  ottoStatusNewHeaderDescription:
    'New = Work Order recommended to technician by Otto and has not previously been endorsed.',
  ottoStatusUnassignedHeaderDescription:
    'Unassigned = Work Order remains unscheduled by Otto.',
  ottoStatusExcludedHeaderDescription:
    'Excluded = Work Orders excluded from Otto consideration set.',
  ottoStatusClosedCancelledHeaderDescription:
    'Work Order Closed/Cancelled = Work Orders previously closed or cancelled in APM and Otto ignored Endorsement ',
  precedenceHeaderDescription:
    'Precedence score ranks work orders in the backlog with the highest impact to the site’s health. This is a weighted score that considers several factors such as 1) Criticality, 2) Priority, 3) Status, 4) Work Order Type, 5) Original Preventative Maintenance Due Date, and 6) If Equipment has Multiple Work Orders.',
  unassignedReasonHeaderDescription:
    'Unassigned reason shows the reason why the work order was not scheduled by Otto.',
  confirmScheduleRefresh: 'Confirm Schedule Refresh',
  refreshScheduleConfirmationDescription:
    'Loading the newest Otto Recommendations will not save any current edits you have made. This cannot be undone.',
  confirmRefresh: 'Confirm Refresh',
  numberOfWOsPerPage: 'Number of WOs per page',
  workOrders: 'Work Orders',
  tablePreferences: 'Table Preferences',
  columnDisplayPreferences: 'Column Display Preferences',
  ottoDailyRunFailed:
    'The Otto Daily Run has failed. The data available below is from the the last successful run',
  hideWOsWithErrors: 'Hide WOs with Errors',
  shiftUtilization: 'Shift Utilization',
  viewShiftUtilization: 'View Shift Utilization',
  uhOh: 'Uh Oh!',
  tryReloading: 'Please try reloading the app.',
  reload: 'Reload',
  addingBulkData: 'Adding Bulk Data',
  useSyntaxToAddBulkData:
    'Use the following syntax to add bulk data in a field',
  commaSeparatedListHere: 'Comma separated list here',
  example: 'Example',
  duplicateValuesRemovedAutomatically:
    'It will take care of removing duplicate values from the data in the field.',
  reorderingValuesInField: 'Reordering values in fields',
  useArrowsToReposition:
    'To reposition, use the left and right arrow on the badge of that particular value.',
  unscheduled: 'Unscheduled',
  pastDates: 'Past Dates',
  noDueDate: 'No Due Date',
  beyond: 'Beyond',
  needHelp: 'Need help?',
};
